import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React, {FunctionComponent, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {RoleAddPlaceholder} from '../../components/Role/RoleAddPlaceholder'
import {RolesContainer} from '../../components/Role/RolesContainer'
import {RoleEditContainer} from '../../container/RoleEditContainer'
import {RoleViewContainer} from '../../container/RoleViewContainer'
import {
  cleanGroupRoleAssignments,
  GroupedRoleAssignment,
  groupRoleAssignments,
  RoleAssignment
} from '../../modules/ManageUsers.selectors'
import {updateRoleAssignment} from '../../modules/Roles.actions'

import {RolesSkeleton} from './Skeleton/RolesSkeleton'

interface Props {
  getRoles: () => void
  showAutoGeneratedRoles: boolean
  isLoading?: boolean
  roleAssignments?: RoleAssignment[] | null
  userId?: string
}

export const Roles: FunctionComponent<Props> = ({
  userId,
  roleAssignments,
  getRoles,
  showAutoGeneratedRoles,
  isLoading = false
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const [showNewRoleAssignmentForm, setShowNewRoleAssignmentForm] = useState(false)

  const filteredRoleAssignments = showAutoGeneratedRoles
    ? roleAssignments
    : roleAssignments?.filter((role) => !role.autoGenerated)

  const groupedRoleAssignments =
    filteredRoleAssignments &&
    groupRoleAssignments(filteredRoleAssignments).map(cleanGroupRoleAssignments)

  const handleRoleAdded = async (roleAssignment: GroupedRoleAssignment) => {
    try {
      roleAssignments && (await updateRoleAssignment(roleAssignments, roleAssignment))
    } catch (e) {
      const error = e as AxiosError
      console.error(error)

      enqueueSnackbar(
        `Role could not be added. Reason: ${
          error.response?.status === 304
            ? 'This Role already exists'
            : (error.response?.data as any)?.message === 'roleAssignment.addRoleError'
              ? t('roleAssignment.addRoleError', {roleName: roleAssignment.roleType})
              : (error.response?.data as any)?.message
        }`,
        {
          variant: error.response?.status === 409 ? 'warning' : 'error'
        }
      )

      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'Roles.tsx handleRoleAdded',
        endpoint: error.response?.config?.url
      })
    }
    setShowNewRoleAssignmentForm(false)
    getRoles()
  }

  const handleUpdateRoleAssignment = async (roleAssignment: GroupedRoleAssignment) => {
    try {
      roleAssignments && (await updateRoleAssignment(roleAssignments, roleAssignment))
    } catch (e) {
      const error = e as AxiosError
      console.error(error)

      enqueueSnackbar(
        `Role could not be updated. Reason: ${
          (error.response?.data as any)?.message === 'roleAssignment.addRoleError'
            ? t('roleAssignment.addRoleError', {roleName: roleAssignment.roleType})
            : (error.response?.data as any)?.message
              ? (error.response?.data as any)?.message
              : error.message
        }`,
        {
          variant: error.response?.status === 409 ? 'warning' : 'error'
        }
      )

      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'Roles.tsx handleUpdateRoleAssignment',
        endpoint: error.response?.config?.url
      })
    }
    getRoles()
  }

  if (isLoading) {
    return (
      <Box style={{display: 'flex'}}>
        <RolesSkeleton />
        <RolesSkeleton />
      </Box>
    )
  }

  const sortRoles = (a: GroupedRoleAssignment, b: GroupedRoleAssignment) => {
    if (a.groupId === null && b.groupId === null) {
      return 0
    } else if (a.groupId === null) {
      return -1
    } else if (b.groupId === null) {
      return 1
    } else {
      return a.groupId.localeCompare(b.groupId)
    }
  }

  const sortedRoleAssignments: GroupedRoleAssignment[] =
    Array.isArray(groupedRoleAssignments) && roleAssignments && groupedRoleAssignments
      ? groupedRoleAssignments.sort((a: GroupedRoleAssignment, b: GroupedRoleAssignment) =>
          sortRoles(a, b)
        )
      : []

  return (
    <RolesContainer>
      {sortedRoleAssignments.map((groupedRoleAssignment) => (
        <RoleViewContainer
          key={groupedRoleAssignment.groupId || 'newGroup'}
          groupedRoleAssignment={groupedRoleAssignment}
          updateRoleAssignments={handleUpdateRoleAssignment}
          getRoles={getRoles}
          roleAssignments={roleAssignments ?? []}
        />
      ))}

      {showNewRoleAssignmentForm && userId ? (
        <RoleEditContainer
          groupedRoleAssignment={{
            groupId: '',
            sourceRolesIds: [],
            roleType: '',
            userId,
            commonDataScopes: {},
            variantDataScopes: {
              customersScopes: [],
              allCustomersRoleId: null
            },
            unsupportedDataScopes: {}
          }}
          updateRoleAssignments={handleRoleAdded}
          onDiscard={() => setShowNewRoleAssignmentForm(false)}
        />
      ) : (
        <RoleAddPlaceholder
          onClick={() => {
            setShowNewRoleAssignmentForm(true)
          }}
        />
      )}
    </RolesContainer>
  )
}
