import {Box, Button, Typography} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {AxiosError} from 'axios'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  RoleConfiguration,
  useRolesConfigurationForUserId
} from '../../common/hooks/useRolesConfiguration'
import {
  RoleCustomerLookupChip,
  RoleCustomerChip
} from '../../components/Role/RoleCustomerLookupChip'
import {useCustomersList} from '../../hooks/useCustomer'
import {CustomersScopes, GroupedRoleAssignment} from '../../modules/ManageUsers.selectors'

import {ContractsModal} from './ContractsModal'
import {useStyles} from './CustomersScopes.styles'
import {CustomersAndProjectsSitesModal} from './CustomersScopesModal'
import {isProjectsCountry} from './utils'

interface CustomersScopesProps {
  groupedRoleAssignment: GroupedRoleAssignment
  setShowCustomerOrPayerDropdown?: (show: boolean) => void
  setShowAnotherCustomerOrPayerDropdown?: (show: boolean) => void
  isEditing?: boolean
  handleDeleteCustomer?: (customerScopes: CustomersScopes) => void
  handleSaveCustomerId?: (customerScopes: CustomersScopes[]) => void
  // TODO: check if we should not really send customerScopes: CustomersScopes[] to make our live with reducer easier.
  handleSaveContractIds?: (contractIds: string[]) => void
}

export const CustomersAndProjectsSites = ({
  groupedRoleAssignment,
  isEditing = false,
  handleDeleteCustomer,
  handleSaveCustomerId,
  handleSaveContractIds,
  setShowCustomerOrPayerDropdown,
  setShowAnotherCustomerOrPayerDropdown
}: CustomersScopesProps): JSX.Element | null => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {customersScopes} = groupedRoleAssignment.variantDataScopes
  const [editingCustomerId, setEditingCustomerId] = useState<string | null>(null)
  const [editingCustomerIdForContracts, setEditingCustomerIdForContracts] = useState<string | null>(
    null
  )

  const {rolesByType} = useRolesConfigurationForUserId()

  const countryId = groupedRoleAssignment.commonDataScopes.countryId
  const orgUnitId = groupedRoleAssignment.commonDataScopes.orgUnitId

  const {
    data: customers,
    isLoading: areCustomersLoading,
    isError: isCustomersError,
    error: customersError
  } = useCustomersList(
    customersScopes.map((scopes) => scopes.customerId),
    countryId as string,
    orgUnitId as string
  )

  const roleConfiguration: RoleConfiguration | undefined =
    rolesByType?.[groupedRoleAssignment.roleType]

  if (isEditing && !roleConfiguration) {
    return null
  }
  const roleDataScopeNames = roleConfiguration
    ? new Set(roleConfiguration.dataScopes.map((d) => d.dataScope))
    : new Set()

  const hasProjectSiteIdScopeInConfiguration =
    roleDataScopeNames.has('siteIds') ||
    roleDataScopeNames.has('projectIds') ||
    roleDataScopeNames.has('siteId') ||
    roleDataScopeNames.has('projectId')

  const hasContractIdScopeInConfiguration =
    roleDataScopeNames.has('contractIds') && roleDataScopeNames.has('contractIds')

  const canSelectProjects = roleDataScopeNames.has('projectIds')
  const canSelectSites = roleDataScopeNames.has('siteIds')

  if (customersScopes.length === 0) {
    return null
  }

  return (
    <>
      {!!editingCustomerId && (
        <CustomersAndProjectsSitesModal
          canSelectProjects={canSelectProjects}
          canSelectSites={canSelectSites}
          open={!!editingCustomerId}
          initialCustomer={editingCustomerId}
          roleType={groupedRoleAssignment.roleType}
          commonDataScopes={groupedRoleAssignment.commonDataScopes}
          customersScopes={groupedRoleAssignment.variantDataScopes.customersScopes}
          onSave={handleSaveCustomerId}
          onClose={() => {
            setEditingCustomerId(null)
          }}
        />
      )}
      {!!editingCustomerIdForContracts && (
        <ContractsModal
          open={Boolean(editingCustomerIdForContracts)}
          onClose={() => {
            setEditingCustomerIdForContracts(null)
          }}
          customerId={editingCustomerIdForContracts}
          customersScopes={groupedRoleAssignment.variantDataScopes.customersScopes}
          handleSaveContractIds={handleSaveContractIds}
        />
      )}
      {/* TODO: let us fix it with the roleCard redesign */}
      {/* eslint-disable-next-line complexity */}
      {customersScopes.map((customerScopes) => {
        const {customerId, siteIds, projectIds, contractIds} = customerScopes

        const isOrderPlacerRole = groupedRoleAssignment.roleType === 'ORDER_PLACER'
        const canEditSites = !isOrderPlacerRole || contractIds.length === 0
        // We do not care about projects in the condition below as those cannot be selected
        const canEditContracts = !isOrderPlacerRole || siteIds.length === 0

        const projectsSitesLabel = (() => {
          const count = siteIds.length + projectIds.length
          const showsSites = countryId && !isProjectsCountry(countryId as string)
          if (count === 0) {
            if (isOrderPlacerRole) {
              return t('roleAssignment.destinationsNumberPlaceholder_all')
            }

            return showsSites
              ? t('roleAssignment.sitesNumberPlaceholder_all')
              : t('roleAssignment.projectsSitesNumberPlaceholder_all')
          }

          if (isOrderPlacerRole) {
            return t('roleAssignment.destinationsNumberPlaceholder', {count: siteIds.length})
          }

          return t(
            showsSites
              ? 'roleAssignment.sitesNumberPlaceholder'
              : 'roleAssignment.projectsSitesNumberPlaceholder',
            {count: siteIds.length + projectIds.length}
          )
        })()

        return (
          <Box className={classes.customerScopesContainer} key={customerId}>
            <Box className={classes.customersRow}>
              {customers && (
                <RoleCustomerChip
                  key={customerId}
                  customer={customers?.find(
                    (c) => c.customerId.toLowerCase() === customerId.toLowerCase()
                  )}
                  isCustomerLoading={areCustomersLoading}
                  isError={isCustomersError}
                  error={customersError as AxiosError}
                />
              )}
              {isCustomersError && customersScopes.length <= 10 && (
                <RoleCustomerLookupChip
                  key={customerId}
                  customerId={customerId}
                  className={classes.chip}
                />
              )}
              {isEditing && (
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={() => {
                    if (
                      handleDeleteCustomer &&
                      setShowAnotherCustomerOrPayerDropdown &&
                      setShowCustomerOrPayerDropdown
                    ) {
                      handleDeleteCustomer(customerScopes)
                      setShowAnotherCustomerOrPayerDropdown(false)
                      setShowCustomerOrPayerDropdown(true)
                    }
                  }}
                >
                  <DeleteIcon />
                </Button>
              )}
            </Box>
            {(siteIds.length || projectIds.length || hasProjectSiteIdScopeInConfiguration) && (
              <Box className={classes.sitesRow}>
                {projectsSitesLabel}
                {isEditing && (
                  <>
                    <Button
                      variant="text"
                      onClick={() => {
                        setEditingCustomerId(customerScopes.customerId)
                      }}
                      disabled={!canEditSites}
                      color="primary"
                      className={classes.editCustomersScopesButton}
                      data-test-id="roleAssignment.editCustomersScopes"
                    >
                      {t('roleAssignment.editCustomersScopes')}
                    </Button>
                  </>
                )}
              </Box>
            )}
            {(contractIds.length || hasContractIdScopeInConfiguration) && (
              <Box display="flex" alignItems="center">
                <Typography>
                  {t(
                    contractIds.length === 0
                      ? 'roleAssignment.contractsPlaceholder_all'
                      : 'roleAssignment.contractsPlaceholder',
                    {
                      contracts: customerScopes.contractIds.join(', ')
                    }
                  )}
                </Typography>
                {isEditing && (
                  <Button
                    variant="text"
                    onClick={() => {
                      setEditingCustomerIdForContracts(customerScopes.customerId)
                    }}
                    disabled={!canEditContracts}
                    color="primary"
                    className={classes.editCustomersScopesButton}
                    data-test-id="roleAssignment.editContracts"
                  >
                    {t('roleAssignment.editContracts')}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        )
      })}
    </>
  )
}
